import React, { useState } from "react";

import "../App.css";

function Footer() {
  
  return (
   
     <div className="bg-black p-[20px] text-center ">
        <p className="text-white">Project Designed by Elizabeth Chikelu (Clizdesigns)</p>
     </div>

  );
}

export default Footer;
